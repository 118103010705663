import { ReactComponent as CallIcon } from 'app/assets/svgs/phone-icon.svg';
import { Button } from 'app/components/v1/Button';
import { Student } from 'app/services/types';
import { useEffect, useState } from 'react';
import { ContactParentModal } from './ContactParentModal';

interface ContactParentButtonProps {
  studentId?: number;
  primaryParentId?: number;
  relId?: number;
  registerId?: number;
  student?: Student;
  className?: string;
  noteRequired?: boolean;
  activeBackground: boolean;
  originalMember?: any;
  role?: any;
  parentId?: number;
}

export const ContactParentButton = (props: ContactParentButtonProps) => {
  const [showCallModal, setShowCallModal] = useState(false);
  const [targetId, setTargetId] = useState<string>(props?.originalMember ?? 'all');
  const [role, setRole] = useState(props?.role);

  useEffect(() => {
    setTargetId(props?.originalMember ?? 'all');
  }, [props.originalMember]);

  useEffect(() => {
    if (props.activeBackground) {
      if (showCallModal) {
        document.body.style.overflow = 'auto';
      } else {
        document.body.style.overflow = '';
      }
    }
  }, [showCallModal]);
  return (
    <>
      <Button
        type="button"
        style={{ backgroundColor: '#2096F3', marginInline: 'auto' }}
        onClick={() => setShowCallModal(true)}
        className={props?.className}
      >
        <CallIcon />
        Contact
      </Button>
      <ContactParentModal
        show={showCallModal}
        setShowCallModal={setShowCallModal}
        studentId={props.studentId}
        student={props.student}
        primaryParentId={props.primaryParentId}
        relId={props.relId}
        registerId={props.registerId}
        noteRequired={props.noteRequired}
        activeBackground={props.activeBackground}
        setTargetId={setTargetId}
        targetId={targetId}
        role={role}
        setRole={setRole}
        parentId={props.parentId}
      />
    </>
  );
};
