import DateText from 'app/components/DateText/DateText';
import { getAttendanceValue } from 'app/features/shared/lesson/register';
import { attendanceResponse, generalIssueResponse } from 'app/utils/consts';
import { displayTimeDiffrence, getTimeDiffrence, utcToLocalDateTime } from 'app/utils/utils';
import moment from 'moment';
import React from 'react';
import { AttendanceSelectInput } from '../../components/AttendanceSelectInput';
import { CheckerAttendanceInput } from '../../components/CheckerAttendanceInput';
import { ContactParentButton } from '../../components/ContactParentButton';
import { LeavePeriodComponent } from '../../components/LeavePeriodComponent';
import { StudentComponent } from '../../components/StudentComponent';

export const tableColumnns = () => {
  const columns: any[] = React.useMemo(
    () => [
      {
        Header: 'Student',
        accessor: 'student',
        Cell: ({ value }: any) => <StudentComponent student={value} />
      },
      {
        Header: 'Teacher Attendance',
        accessor: 'teacherAttendance',
        Cell: ({ value }: any) => (
          <AttendanceSelectInput
            value={getAttendanceValue(value.status)!}
            id={value.relId}
            registerId={value.registerId}
          />
        )
      },
      {
        Header: 'Checker Attendance',
        accessor: 'checkerAttendance',
        Cell: ({ value }: any) => (
          <CheckerAttendanceInput value={value.value} relId={value.relId} registerId={value.registerId} />
        )
      },
      {
        Header: 'Parent Contact',
        accessor: 'contactParent',
        Cell: ({ value }: any) => (
          <ContactParentButton
            studentId={value.studentId}
            primaryParentId={value.primaryParentId}
            relId={value.relId}
            registerId={value.registerId}
            student={value.student}
            activeBackground={true}
            role="student"
            originalMember={value?.studentId?.toString()}
          />
        )
      },
      {
        Header: 'Last Call Status',
        accessor: 'status',
        Cell: ({ value }: any) =>
          value ? (
            <div className="d-flex flex-column" style={{ color: '#92929d', fontWeight: '500', fontSize: '14px' }}>
              <span className="no-wrap">{`Called - ${
                value.reason == 'attendance'
                  ? `${attendanceResponse.filter((resp) => resp.value == value.response)?.[0]?.label}`
                  : `${generalIssueResponse.filter((resp) => resp.value == value.response)?.[0]?.label}`
              }`}</span>
              <span>
                {getTimeDiffrence(value.timestamp).days == 0 ? (
                  <DateText
                    date={utcToLocalDateTime(
                      value.timestamp,
                      moment.utc(value.timestamp).format('HH:mm')
                    ).toISOString()}
                    format={'time'}
                  />
                ) : (
                  displayTimeDiffrence(value.timestamp)
                )}
              </span>
            </div>
          ) : (
            <></>
          )
      },
      {
        Header: 'Leave Period',
        accessor: 'leavePeriod',
        Cell: ({ value }: any) => <LeavePeriodComponent leavePeriod={value} />
      }
    ],
    []
  );
  return columns;
};
