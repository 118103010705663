import { TimePicker as AntdTimePicker, SelectProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { TimeInterval } from 'app/utils/utils';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { CSSProperties, useCallback } from 'react';
import styled from 'styled-components';
import { Errors } from './Input';

const LabelStyled = styled.label`
  position: absolute;
  background-color: white;
  border-radius: 14px;
  top: 12px;
  left: 14px;
  color: #b5b5be;
  transition: top 100ms ease-in-out, left 100ms ease-in-out;
  user-select: none;
  &.active {
    top: -8px;
    left: 0.875rem;
    font-size: 12px;
    color: #4c54a0;
  }
  &.disabled {
    top: -8px;
    left: 0.875rem;
    font-size: 12px;
    color: #b5b5be;
  }
`;

const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div {
    width: 100%;
    min-width: 80px;
  }
  .ant-select {
    width: 100%;
    // height: 48px;
    text-align: start;

    &.ant-select-disabled {
      .ant-select-selector {
        border-color: #d3d3d8 !important;
      }
    }
  }

  .ant-select-selector {
    // max-height: 50px;
    overflow: hidden;
    border-radius: 12px;
  }
  .ant-select-disabled {
    .ant-select-selector {
      background: transparent !important;
      border-color: #4c54a0 !important;
      box-shadow: none !important;
    }
  }
`;

export function TimePicker(props: TimePickerProps) {
  const defaultFormat = 'HH:mm';
  const onChange = useCallback(
    (value: Dayjs | null, dateString: string | string[]) => {
      props.onChange && props.onChange(value?.format(defaultFormat)!);
    },
    [props.onChange]
  );

  const disabledRangeTime: RangePickerProps['disabledTime'] = (value, type) => {
    const disabledHours: number[] = [];
    const disabledMins: number[] = [];
    if (props.minTime) {
      disabledHours.push(..._.range(0, Number(dayjs(props.minTime, defaultFormat).format('HH'))));
    }
    if (props.maxTime) {
      disabledHours.push(..._.range(Number(dayjs(props.maxTime, defaultFormat).format('HH')), 24));
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: (selectedHour) => {
        if (props.minTime && selectedHour == Number(dayjs(props.minTime, defaultFormat).format('H'))) {
          disabledMins.push(..._.range(0, Number(dayjs(props.minTime, defaultFormat).format('mm'))));
        }
        if (props.maxTime && selectedHour == Number(dayjs(props.maxTime, defaultFormat).format('H'))) {
          disabledMins.push(..._.range(Number(dayjs(props.maxTime, defaultFormat).format('mm')), 60));
        }

        return disabledMins;
      }
    };
  };

  return (
    <ContainerStyled>
      <AntdTimePicker
        onChange={onChange}
        format={defaultFormat}
        value={props.value ? dayjs(props.value, defaultFormat) : null}
        allowClear={props.allowClear}
        minuteStep={5}
        disabled={props.disabled}
        disabledTime={disabledRangeTime as any}
        name={props.name}
        needConfirm={false}
        placeholder={props.placeholder}
      />

      {props.name && props.value && (
        <LabelStyled
          className={`${props.disabled? 'disabled':'active'}`}
        >
          {props.name}
        </LabelStyled>
      )}
      {props.errors && <Errors errors={props.errors} />}
    </ContainerStyled>
  );
}

export type TimePickerProps = {
  select?: SelectProps;
  name?: string;
  value?: string;
  timeInterval?: number;
  intervals?: TimePickerTimeInterval[];
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  allowClear?: boolean;
  errors?: { [key: string]: string[] };
  hint?: string;
  minTime?: any;
  maxTime?: any;
  onChange?: (value: string) => void;
  placeholder?: string;
};
export type TimePickerTimeInterval = TimeInterval & {
  disabled?: boolean;
};
