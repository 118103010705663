import { ReactComponent as ClockIcon } from 'app/assets/svgs/clock-icon.svg';
import styled from 'styled-components';

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 70px;

  .entry {
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;

    svg {
      width: 15px;
      height: 15px;
      path {
        stroke: #92929d;
        stroke-width: 2;
        font-weight: 500;
      }
    }

    .time-text {
      font-size: 12px;
      color: #92929d;
    }
    .name-text {
      font-size: 12px;
      color: #44444f;
    }
  }
`;
interface CheckinPopoverProps {
  logs: any;
}

export const CheckinLogPopover = (props: CheckinPopoverProps) => {
  return (
    <PopoverContainer>
      {props.logs?.map((log: any, index: number) => (
        <div className="entry" key={index}>
          <ClockIcon />
          <span className="time-text">{new Date(log?.timestamp).toLocaleString()}</span>
          <span className="name-text">{log?.admin?.data?.attributes?.name || log?.admin?.name}</span>
        </div>
      ))}
    </PopoverContainer>
  );
};
