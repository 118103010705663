import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Subject } from 'app/utils/hooks/student';
import { utcToLocalDateTime } from 'app/utils/utils';
import qs from 'qs';
import api from '.';
import {
  ApiResponse,
  ApiResponseData,
  GetQueryPayload,
  GetStudentResponse,
  GetStudentsResponse,
  RegisterStudent,
  StudentClass,
  StudentClassStatus
} from './types';

export const studentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateStudent: builder.mutation<any, { profileData: any; id: string }>({
      query: (body) => ({
        url: `/api/students/${body.id}`,
        method: 'PUT',
        body: { data: body.profileData }
      }),
      invalidatesTags: (result, error, payload) => {
        return [
          { type: 'Student' },
          { type: 'User' },
          { type: 'Student', id: payload.id },
          { type: 'FamilyLinks', id: `student-${payload.id}` },
          { type: 'Register' }
        ];
      }
    }),
    createStudent: builder.mutation<any, { profileData: any }>({
      query: (body) => ({
        url: `/api/students`,
        method: 'POST',
        body: { data: body.profileData }
      }),
      invalidatesTags: ['Student']
    }),

    getStudents: builder.query<GetStudentsResponse, GetQueryPayload>({
      query: (payload) => ({
        url: `/api/students?${payload.requestQuery}`,
        method: 'GET'
      }),
      providesTags: (result, error, payload) => {
        const tags: any = [];
        result?.data.forEach((e) => {
          tags.push({ type: 'FamilyLinks', id: `student-${e.id}` }, { type: 'Student', id: e.id });
        });
        return tags;
      }
    }),
    getStudentById: builder.query<GetStudentResponse, GetQueryPayload & { studentId: string }>({
      query: (payload) => {
        return {
          url: `/api/students/${payload.studentId}?${payload.requestQuery}`,
          method: 'GET'
        };
      },
      providesTags: (result, error, payload) => {
        return [
          { type: 'Student' },
          { type: 'Student', id: payload.studentId },
          { type: 'FamilyLinks', id: `student-${payload.studentId}` }
        ];
      }
    }),
    deleteStudent: builder.query<any, string>({
      query: (id) => {
        return {
          url: `/api/students/${id}`,
          method: 'DELETE'
        };
      }
    }),
    getStudentClasses: builder.query<ApiResponse<ApiResponseData<StudentClass>[]>, GetQueryPayload>({
      query: (payload) => {
        return {
          url: `/api/student-classes?${payload.requestQuery}`,
          method: 'GET'
        };
      },
      providesTags: ['Student']
    }),
    getStudentSubjects: builder.query<
      Subject[],
      {
        studentIds: string[];
        filters?: { yearId?: string; subjectId?: string };
      }
    >({
      providesTags: ['StudentClass', 'RegisterStudent', 'Student'],
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        if (!_arg?.studentIds?.length) return { data: [] };
        try {
          const availableStudentClassesStatues = [
            StudentClassStatus.TRAIL,
            StudentClassStatus.AWAITING_FEEDBACK,
            StudentClassStatus.ACTIVE,
            StudentClassStatus.PAYMENT_OVERDUE
          ];
          const cancelledStudentClassesStatues = [
            StudentClassStatus.CANCELLED,
            StudentClassStatus.CANCELLATION_REQUESTED,
            StudentClassStatus.INACTIVE
          ];
          const studentClasses: ApiResponse<ApiResponseData<StudentClass>[]> = (
            (await fetchWithBQ(
              `/api/student-classes?${qs.stringify(
                {
                  pagination: {
                    limit: 10000
                  },
                  populate: {
                    class: {
                      populate: ['subject', 'year', 'teacher']
                    },
                    student: {
                      field: ['id']
                    }
                  },
                  filters: {
                    $or: [
                      {
                        status: {
                          $in: availableStudentClassesStatues
                        }
                      },
                      {
                        status: {
                          $in: cancelledStudentClassesStatues
                        }
                      }
                    ],
                    student: {
                      id: {
                        $in: _arg?.studentIds
                      }
                    },
                    class: {
                      subject: _arg?.filters?.subjectId,
                      year: _arg?.filters?.yearId
                    }
                  },
                  sort: ['class.year.order:desc', 'class.subject.order:desc']
                },
                {
                  encodeValuesOnly: true
                }
              )}`
            )) as any
          )?.data;

          const cancelledStudentClasses = studentClasses?.data?.filter((e) =>
            cancelledStudentClassesStatues.includes(e.attributes?.status as any)
          );

          const availableStudentClasses = studentClasses?.data?.filter((e) =>
            availableStudentClassesStatues.includes(e.attributes?.status as any)
          );

          if (cancelledStudentClasses?.length) {
            const cancelledStudentClassesRegisterStudents = (
              (await fetchWithBQ(
                `/api/register-students?${qs.stringify({
                  fields: ['id'],
                  filters: {
                    student_class: {
                      $in: cancelledStudentClasses?.map((e) => e.id)
                    }
                  },
                  pagination: {
                    limit: 10000
                  },
                  populate: {
                    student_class: {
                      fields: ['id']
                    }
                  }
                })}`
              )) as any
            ).data as ApiResponse<ApiResponseData<RegisterStudent>[]>;

            const cancelledStudentClassesRegisterStudentsIds = cancelledStudentClassesRegisterStudents?.data?.map((a) =>
              a.attributes?.student_class?.data?.id?.toString()
            );
            cancelledStudentClasses?.forEach((e) => {
              if (cancelledStudentClassesRegisterStudentsIds?.includes(e?.id?.toString()!)) {
                availableStudentClasses.push(e);
              }
            });
          }

          const subjects: Subject[] = [];

          availableStudentClasses?.forEach((_studentClass) => {
            const itsClass = _studentClass?.attributes?.class?.data;
            const classSubject = itsClass?.attributes?.subject?.data;
            const classTeacher = itsClass?.attributes?.teacher?.data;
            const classYear = itsClass?.attributes?.year?.data;
            let existsSubject = subjects.find(
              (s) =>
                s?.studentId?.toString() === _studentClass?.attributes?.student?.data?.id?.toString() &&
                s?.subjectId?.toString() === classSubject?.id?.toString() &&
                s?.year?.id?.toString() === classYear?.id?.toString()
            );
            if (!existsSubject) {
              existsSubject = {
                id: classSubject?.id?.toString(),
                subjectId: classSubject?.id?.toString(),
                name: classSubject?.attributes?.name,
                order: classSubject?.attributes?.order,
                studentId: _studentClass?.attributes?.student?.data?.id?.toString(),
                year: {
                  id: classYear?.id,
                  name: classYear?.attributes?.name,
                  shortName: classYear?.attributes?.shortName,
                  order: classYear?.attributes?.order
                },
                classes: []
              };
              subjects.push(existsSubject);
            }
            const isClassExists = existsSubject.classes.find((e) => e.classId?.toString() === itsClass?.id?.toString());
            if (!isClassExists) {
              existsSubject.classes.push({
                classId: itsClass?.id?.toString()!,
                dayOfWeek: itsClass?.attributes?.dayOfWeek!,
                name: itsClass?.attributes?.classId!,
                teacher: {
                  id: classTeacher?.id?.toString()!,
                  name: classTeacher?.attributes?.name!
                },
                startAt: utcToLocalDateTime(itsClass?.attributes?.startDate!, itsClass?.attributes?.startTime),
                endsAt: utcToLocalDateTime(itsClass?.attributes?.startDate!, itsClass?.attributes?.endTime)
              });
            }
          });
          return { data: subjects };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      }
    })
  })
});
