import { Modal } from 'antd';
import NoteComponent from 'app/components/NoteComponent/NoteComponent';
import useNote from 'app/components/NoteComponent/useNote';
import { UserType } from 'app/services/types';
import { MAIN_SELECTORS } from 'app/store';
import { useSelector } from 'react-redux';

interface StudentRegisterNotesProps {
  addNoteOpen: boolean;
  setAddNoteOpen: (x: boolean) => void;
  studentId: string;
  regId?: string;
  classId?: string;
}

export const StudentRegisterNotes = (props: StudentRegisterNotesProps) => {
  const user = useSelector(MAIN_SELECTORS.user);

  const {
    addNote,
    notes,
    updateNote,
    deleteNote,
    isLoading: isNotesLoading
  } = useNote({
    sourceEntityId: user?.userType == UserType.Admin.toLowerCase() ? user?.entity?.id : undefined,
    targetEntityId: props?.studentId?.toString()!,
    teacherId: user?.userType == UserType.Teacher.toLowerCase() ? user?.teacher?.id : undefined,
    regId: props?.regId,
    classId: props?.classId,
    source: 'register',
    type: 'note'
  });
  return (
    <>
      <Modal
        width={'50%'}
        zIndex={10}
        open={props.addNoteOpen}
        destroyOnClose
        footer={[<></>]}
        onCancel={() => {
          props.setAddNoteOpen(false);
        }}
      >
        <NoteComponent
          value={notes || []}
          loading={isNotesLoading}
          onDelete={async (id) => {
            await deleteNote(id);
          }}
          onSubmit={async (value) => {
            if (value.id) {
              await updateNote(value?.id, value.content);
            } else {
              await addNote(value.content);
            }
          }}
          familyNotes={false}
        />
      </Modal>
    </>
  );
};
